import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { changePassword } from "./cognito";

import "./ChangePassword.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

const antIconResending = <LoadingOutlined style={{ fontSize: 16 }} spin />;
function ChangePassword({ authStore }) {
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [newPassword, setNewPassword] = useState(true);
  const [newPassword2, setNewPassword2] = useState(true);

  const [currentPassword, setCurrentPassword] = useState("");

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    if (newPassword !== newPassword2) {
      setError("Password mismatch");
      setSubmitting(false);
      return;
    }
    try {
      await changePassword(currentPassword, newPassword);
      setSuccess("Password changed successfully.");
    } catch (e) {
      setError(e.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="ChangePassword">
      {success ? (
        <div className="success">{success}</div>
      ) : (
        <div className="ChangePassword__wrapper">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            {
              <React.Fragment>
                <Form.Item
                  className="ChangePassword__item"
                  label="Current password"
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your current password",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="ChangePassword__item"
                  label="New Password"
                  name="newPassword"
                  rules={[
                    { required: true, message: "Please enter a new password!" },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="ChangePassword__item"
                  label="Re-enter Password"
                  name="newPassword1"
                  rules={[
                    {
                      required: true,
                      message: "Please re-enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword2(e.target.value)}
                  />
                </Form.Item>
              </React.Fragment>
            }
            {error && <div className="error">{error}</div>}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> {"Change Password"}</span>}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default observer(ChangePassword);
