import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import CardSetup from "./CardSetup";
import CardBillingInfo from "./CardBillingInfo";
import { config } from "../../utils/config";

import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Tag, Card, Modal, Spin, Button, Form, Row, Col, Table } from "antd";

import {
  loadRoles,
  setupIntent,
  resumeSubscription,
  subscribe,
  deletePaymentMethod,
  deleteSubscription,
  loadPlans,
  getInvoices,
} from "../../service/api_service";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./Account.css";
import PlanSelection from "./PlanSelection";
import PaymentStepWizard from "./PaymentStepWizard";
const { Meta } = Card;

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 18, color: "black", margin: "0 5px" }}
    spin
  />
);

const antIconLarge = (
  <LoadingOutlined
    style={{ fontSize: 24, color: "black", margin: "0 5px" }}
    spin
  />
);

const { confirm } = Modal;

function Account({ jobStore }) {
  jobStore.setNavigation("account");

  const [me, setMe] = useState(null);
  const [plans, setPlans] = useState(null);
  const [roles, setRoles] = useState(null);

  const [loading, setLoading] = useState(false);

  const [isCardValid, setIsCardValid] = useState(false);
  const [cardClientSecret, setCardClientSecret] = useState(null);
  const [cardClearProcessing, setCardClearProcessing] = useState(false);
  const [cardSaveProcessing, setCardSaveProcessing] = useState(false);
  const [upgradeToPaid, setUpgradeToPaid] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [monthlyFrequency, setMonthlyFrequency] = useState(true);
  const [preparingUpgrade, setPreparingUpgrade] = useState(false);
  const [preparingDowngrade, setPreparingDowngrade] = useState(false);
  const [plannedSubscription, setPlannedSubscription] = useState(null);
  const [purchasing, setPurchasing] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await refresh();
      const intent = await setupIntent();
      setCardClientSecret(intent.client_secret);
      setLoading(false);
    };
    fetch();
  }, []);

  const refresh = async () => {
    const plans_ = loadPlans();
    const roles_ = loadRoles(true);
    const plans = await plans_;
    const { me, roles } = await roles_;
    setMe(me);
    setPlans(plans);
    setRoles(roles);
    console.log(me);
    console.log(plans);
    console.log(roles);
    const invoices = await getInvoices();
    setInvoices(invoices.data);
  };

  const prepareCancelSubscription = async () => {
    //    cancelSubscription();
    const title = `Are you sure you want to cancel your subscription ?`;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Cancel Subscription",
      okType: "danger",
      cancelText: "Keep Subscription",
      onOk() {
        cancelSubscription();
      },
      onCancel() {},
    });
  };
  const cancelSubscription = async () => {
    setCancelling(true);
    await deleteSubscription();
    await refresh();
    setCancelling(false);
  };
  const onResumeSubscription = async () => {
    setCancelling(true);
    await resumeSubscription(me.subscription);
    await refresh();
    setCancelling(false);
  };
  const prepareUpgrade = async (subscription) => {
    setPreparingUpgrade(true);
    setPlannedSubscription(subscription);
  };
  const purchase = async () => {
    setPurchasing(true);
    await subscribe(plannedSubscription, frequency);
    await refresh();
    setPurchasing(false);
    setPreparingUpgrade(false);
    setPlannedSubscription(null);
    setPreparingDowngrade(false);
  };

  const upgrade = async (subscription) => {
    if (subscription === "PAID") {
      setUpgradeToPaid(true);
    }
    await subscribe(subscription, frequency);
    await refresh();
    setUpgradeToPaid(false);
  };
  const cardError = (error) => {
    setIsCardValid(!error);
  };
  const clearPaymentMethod = async () => {
    setCardClearProcessing(true);
    await deletePaymentMethod();
    await refresh();
    setCardClearProcessing(false);
  };

  const savedCard = useMemo(() => {
    if (me && me["payment_method"]) {
      return me["payment_method"]["card"];
    }
    return null;
  }, [me]);

  const hasCard = () => {
    return savedCard != null;
  };

  const frequency = useMemo(() => {
    return monthlyFrequency ? "monthly" : "yearly";
  }, [monthlyFrequency]);

  const computedFullname = useMemo(() => {
    if (me != null) {
      return `${me.given_name} ${me.family_name}`;
    } else {
      return "";
    }
  }, []);

  const address = useMemo(() => {
    if (me != null && me && me.billing_info && me.billing_info.address) {
      return me.billing_info.address;
    } else {
      return null;
    }
  }, [me]);

  const remainingUrlMappings = useMemo(() => {
    if (me && me.meter && me.plan_limits) {
      return me.meter.predict_urls;
    }
    return 0;
  }, [me]);

  const usagePercent = useMemo(() => {
    if (me && me.meter && me.plan_limits) {
      let percent = (me.meter.predict_urls / me.plan_limits) * 100;
      percent = Math.min(100, parseInt(percent));
      return `${percent}%`;
    } else {
      return 0;
    }
  }, [me]);

  const columns = [
    {
      title: "",
      dataIndex: "created",
      key: "created",
      render: (created, _) => {
        const date = new Date(created * 1000);
        const options = { month: "long", day: "numeric", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
      },
    },
    {
      title: "",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (amount_paid, _) => {
        return `$ ${amount_paid / 100}`;
      },
    },
    {
      title: "",
      dataIndex: "invoice_pdf",
      key: "invoice_pdf",
      render: (invoice_pdf, _) => {
        return (
          <a href={invoice_pdf} download>
            Download Invoice
          </a>
        );
      },
    },
  ];

  const showDowngradetoFree = () => {
    confirm({
      title: (
        <div>
          Are you sure you want to downgrade to free plan?
          {me.current_period_end && <div style={{ fontSize: "12px" }}>
            You will still be able to use the current plan until{" "}
            {me.current_period_end.substring(0, 10)}.
          </div>}
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Downgrade",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteSubscription();
        refresh();
      },
      onCancel() {},
    });
  };

  console.log(me?.subscription, savedCard,  address);

  return (
    <div className="Account">
      <Modal
        title="Plans"
        okText="Save"
        footer={null}
        open={isPlanModalOpen}
        width={"800px"}
        onCancel={() => setIsPlanModalOpen(false)}
      >
        <PlanSelection
          jobStore={jobStore}
          me={me}
          refresh={refresh}
          close={setIsPlanModalOpen}
        ></PlanSelection>
      </Modal>

      <Modal
        title="Billing Info"
        okText="Save"
        footer={null}
        open={isBillingInfoModalOpen}
        onCancel={() => setIsBillingInfoModalOpen(false)}
      >
        <CardBillingInfo
          jobStore={jobStore}
          me={me}
          refresh={refresh}
          close={setIsBillingInfoModalOpen}
        ></CardBillingInfo>
      </Modal>

      <Modal
        title="Payment Method"
        open={isPaymentModalOpen}
        footer={null}
        onCancel={() => setIsPaymentModalOpen(false)}
      >
        <Elements stripe={stripePromise}>
          <CardSetup
            cardClientSecret={cardClientSecret}
            jobStore={jobStore}
            me={me}
            refresh={refresh}
            close={setIsPaymentModalOpen}
          ></CardSetup>
        </Elements>
      </Modal>
      {loading && <Spin indicator={antIconLarge}></Spin>}
      {me?.subscription && (me.subscription !== "FREE" || savedCard || address) && !loading && (
        <div>
          <h1>Billing and Plan</h1>
          <div>Manage your Redirect Mapper subscription</div>
          <Row style={{ marginTop: "20px" }}>
            <Col md={24} lg={12} style={{ paddingRight: "40px" }}>
              <Button
                size="small"
                style={{ float: "right" }}
                onClick={() => setIsPlanModalOpen(true)}
              >
                Edit Plan
              </Button>
              <h4>Current plan</h4>
              {me && me.subscription && (
                <div style={{ clear: "both", marginTop: "20px" }}>
                  {me.subscription === "PERSONAL" && (
                    <div>Personal - up to 1000 redirects per month</div>
                  )}
                  {me.subscription === "PAID" && (
                    <div>Paid - up to 1000 redirects per month</div>
                  )}
                  {me.subscription === "POPULAR" && (
                    <div>Popular - up to 10,000 redirects per month</div>
                  )}
                  {me.subscription === "AGENCY" && (
                    <div>Agency - up to 50,000 redirects per month</div>
                  )}
                  {me.subscription === "FREE" && (
                    <div>Free Plan - up to 100 redirects per month</div>
                  )}
                  {me.subscription === "ULIMIT" && (
                    <div>Unlimited Plan - up to 5,000,000 redirects per month</div>
                  )}
                  {me.subscription === "TESTING" && (
                    <div>Test Plan - up to 100 redirects per month</div>
                  )}
                </div>
              )}

              <div style={{ fontSize: "0.75rem" }}>
                {["PAID", "PERSONAL", "POPULAR", "AGENCY"].indexOf(
                  me.subscription
                ) >= 0 &&
                  me.subscription_canceled && (
                    <div>
                      Your subscription cancels on{" "}
                      {me.current_period_end.substring(0, 10)}. No future
                      billing.
                      {cancelling && <Spin indicator={antIcon}></Spin>}
                    </div>
                  )}
              </div>

              {remainingUrlMappings != null && (
                <div style={{ marginTop: "20px", fontSize: "0.75rem" }}>
                  <div className="usage">
                    <div className="outer">
                      <div
                        className="inner"
                        style={{ width: usagePercent }}
                      ></div>
                    </div>
                  </div>
                  <span style={{ float: "right" }}>
                    {remainingUrlMappings} / {me.plan_limits}
                  </span>
                  <span>Mappings Usage:</span>
                </div>
              )}

              {/*
                Free Plan - up to 100 redirects per month
                $79 per month - up to 1,000 redirects per month
                $249 per month - up to 10,000 redirects per month
                $449 per month - up to 50,000 redirects per month
                */}
            </Col>
            <Col md={24} lg={12}>
              <div>
                <Button
                  size="small"
                  style={{ float: "right" }}
                  onClick={() => setIsBillingInfoModalOpen(true)}
                >
                  Edit billing info
                </Button>
                <h4>Billing information</h4>
                <div style={{ clear: "both", marginTop: "20px" }}>
                  {address && (
                    <div style={{ marginBottom: "30px" }}>
                      <div>{me.billing_info.name}</div>
                      <div>
                        {address["line2"]} {address["line1"]}
                      </div>
                      <div>
                        {address["city"]}, {address["state"]}
                        {address["postal_code"]}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Button
                  size="small"
                  style={{ float: "right" }}
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  Edit payment method
                </Button>
                <h4>Payment method</h4>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                {savedCard && (
                  <div>
                    <div>
                      Card{" "}
                      <img
                        src={`/cards/${savedCard["brand"]}.svg`}
                        width="24px"
                        style={{ margin: "5px" }}
                        alt={savedCard["brand"]}
                      />
                      ending in {savedCard["last4"]}
                    </div>
                    <div>
                      Expire end of {savedCard["exp_month"]}/
                      {savedCard["exp_year"]}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <div style={{ marginTop: "40px" }}>
            <h1>Payment history</h1>
            <Table
              columns={columns}
              showHeader={false}
              pagination={true}
              dataSource={invoices}
              rowKey="paymentHistroy"
            ></Table>
          </div>
        </div>
      )}
      {me?.subscription &&
        me.subscription === "FREE" &&
        (!savedCard && !address) &&
        !loading && (
          <div>
            <Row>
              <Col md={24} lg={12}>
                <h1>Billing and Plan</h1>
                <div>Manage your Redirect Mapper subscription</div>
          
                <div style={{ clear: "both", marginTop: "20px", marginBottom: "50px" }}>
                  <h4>Current plan</h4>
                    {me.subscription === "FREE" && (
                      <div>Free Plan - up to 100 redirects per month</div>
                    )}
                    
                    {remainingUrlMappings != null && (
                    <div style={{ marginTop: "20px", fontSize: "0.75rem" }}>
                      <div className="usage">
                        <div className="outer">
                          <div
                            className="inner"
                            style={{ width: usagePercent }}
                          ></div>
                        </div>
                      </div>
                      <span style={{ float: "right" }}>
                        {remainingUrlMappings} / {me.plan_limits}
                      </span>
                      <span>Mappings Usage:</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <PaymentStepWizard jobStore={jobStore} refresh={refresh} />
          </div>
        )}

      {me?.subscription &&
        me.subscription !== "FREE" &&
        !me.subscription_canceled &&
        !loading && (
          <div style={{ marginBottom: "20px" }}>
            <h1>Downgrade to free plan</h1>
            <Button danger onClick={showDowngradetoFree}>
              Downgrade
            </Button>
          </div>
        )}
    </div>
  );
}

export default observer(Account);
