import { makeAutoObservable, action, computed } from "mobx";

export class JobStoreImpl {
  jobCounter = 0;
  navigation = "automated";

  incrementJobCounter() {
    this.jobCounter += 1;
  }

  setNavigation(navigation){
    this.navigation = navigation;
  }
  constructor() {
    makeAutoObservable(this, {
      incrementJobCounter: action,
      setNavigation: action,
    });
  }
}

export const JobStore = new JobStoreImpl();
