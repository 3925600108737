import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import {
  DeleteOutlined,
  EditOutlined,
  CodeOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  PauseCircleTwoTone,
  PlayCircleTwoTone,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Table,
  Spin,
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Empty,
  Switch,
} from "antd";
import { useParams } from "react-router-dom";
import {
  loadProjectDetail,
  getProjectAgg,
  updateProject,
  deleteRedirectUrl,
  updateRedirectUrl,
  getProjectRedirectUrls,
  getProjectRedirectUrlsByDay,
} from "../../service/api_service";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

import CsvDownloadButton from "react-json-to-csv";

import "./Project.css";

const { RangePicker } = DatePicker;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
);

const antIconBlack = (
  <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />
);

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Project({ jobStore }) {
  jobStore.setNavigation("automated");
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const [fullUrlMode, setFullUrlMode] = useState(true);

  const [projectDetail, setProjectDetail] = useState({});
  const [projectAgg, setProjectAgg] = useState(null);
  const [projectAggFiltered, setProjectAggFiltered] = useState(null);

  const [dataSourceForRedirect, setDataSourceForRedirect] = useState([]);
  const [pageCursorRedirect, setPageCursorRedirect] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  const [original, setOriginal] = useState("");
  const [match, setMatch] = useState("");
  const [visible, setVisible] = useState(false);
  const [playPause, setPlayPause] = useState([]);
  const [deleteProgress, setDeleteProgress] = useState([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [createError, setCreateError] = useState("");
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRedirectRecord, setEditRedirectRecord] = useState(null);
  const [editRecordMatch, setEditRecordMatch] = useState(null);

  const { confirm } = Modal;


  const onStripUrlToggleChange = (value) => {
    setFullUrlMode(value);
  }

  const togglePlayPause = async (original, value) => {
    setPlayPause([...playPause, original]);
    try {
      await updateProject(id, { pause: value, original: original });
      fetchData();
    } finally {
      setPlayPause(playPause.splice(playPause.indexOf(original), 1));
    }
  };

  const onUrlEdit = async (record) => {
    setEditRedirectRecord(record);
    const matchText = record.manual_url ? record.manual_url : record.match;
    setEditRecordMatch(matchText);
    editForm.setFieldsValue({
      match: matchText,
    });
    setEditModalOpen(true);
  };

  const onUrlEditSave = async () => {
    setSubmitting(true);
    try {
      const payload = {
        original: editRedirectRecord.original,
        match: editRecordMatch,
      };
      await updateRedirectUrl(id, payload);
      setDataSourceForRedirect([]);
      setPageCursorRedirect(null);
      fetchData();
    } finally {
      setEditModalOpen(false);
      setSubmitting(false);
    }
  };

  const dateFormatter = (date) => {
    return moment(date).format("MMM DD");
  };

  const onUrlDelete = async (original) => {
    setDeleteProgress([...deleteProgress, original]);

    try {
      confirm({
        title: "Confirm url deletion",
        icon: <ExclamationCircleOutlined />,
        content: "",
        okText: "Delete URL",
        okType: "danger",
        cancelText: "cancel",
        async onOk() {
          await deleteRedirectUrl(id, { original: original });
          setDataSourceForRedirect([]);
          setPageCursorRedirect(null);
          fetchData();
        },
        onCancel() {},
      });
    } finally {
      setDeleteProgress(
        deleteProgress.splice(deleteProgress.indexOf(original), 1)
      );
    }
  };

  const columns = [
    {
      title: "404 page",
      dataIndex: "original",
      key: "original",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Redirects",
      dataIndex: "redirects",
      key: "redirects",
      width: "5%",
      render: (redirects, record) => numberWithCommas(record.redirects),
    },
    {
      title: "Referrer",
      dataIndex: "referrer",
      key: "referrer",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text && text !== "undefined" ? text : ""}
        </div>
      ),
    },
    {
      title: "Redirect To",
      dataIndex: "match",
      key: "match",
      width: "40%",
      render: (text, record) => {
        let strikeThroughText = "";
        let currentText = text;
        if (record.manual_url && record.manual_url !== text) {
          strikeThroughText = text;
          currentText = record.manual_url;
        }
        return (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            <div
              style={{
                textDecoration: "line-through",
                wordWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              {strikeThroughText}
            </div>
            {currentText}
          </div>
        );
      },
    },
    {
      title: "",
      key: "",
      width: "100px",
      render: (_, record) => {
        const playPauseValue = record?.pause ? record.pause : false;
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div key="actions" className="Jobs__action">
            <a
              style={{ color: "#343434", marginRight: "10px" }}
              onClick={() => onUrlEdit(record)}
            >
              <EditOutlined />
            </a>

            <a
              style={{ color: "#343434", marginRight: "10px" }}
              onClick={() => onUrlDelete(record.original)}
            >
              {deleteProgress.indexOf(record.original) >= 0 ? (
                <Spin indicator={antIconBlack}></Spin>
              ) : (
                <DeleteOutlined />
              )}
            </a>

            <a
              onClick={() =>
                togglePlayPause(record.original, !playPauseValue, record)
              }
            >
              {playPause.indexOf(record.original) >= 0 ? (
                <Spin indicator={antIconBlack}></Spin>
              ) : playPauseValue ? (
                <PlayCircleTwoTone twoToneColor="#7FB367" />
              ) : (
                <PauseCircleTwoTone twoToneColor="#FFC806" />
              )}
            </a>
          </div>
        );
      },
    },
  ];

  const script = () => {
    if (projectDetail) {
      const src = `${window.location.origin}/dist/app.bundle.js`;
      // eslint-disable-next-line no-useless-escape
      const script = `<script src="${src}" class="${id}" id="redirectMapperID"><\/script>`;
      return script;
    }
    return "";
  };

  const onFinish = async () => {
    setCreateError("");
    if (original.split("\n").length !== match.split("\n").length) {
      setCreateError("Number of original and final rows doesn't match.");
      return;
    }
    setSubmitting(true);
    await updateProject(id, {
      add_redirect: true,
      original: original,
      match: match,
    });
    fetchData();
    setVisibleForm(false);
    setSubmitting(false);
  };

  const onCreateRedirect = async () => {
    form.resetFields();
    setVisibleForm(true);
  };

  const fetchMoreRedirectsByDay = async (
    more = true,
    startDate = null,
    endDate = null,
  ) => {
    if (startDate === null && endDate === null) {
      startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      endDate = moment(dateRange[1]).format("YYYY-MM-DD");
    }
    if (pageCursorRedirect === null || !more) {
      setDataSourceForRedirect([]);
    }
    let urls;
    if (more) {
      urls = await getProjectRedirectUrlsByDay(
        id,
        100,
        pageCursorRedirect,
        startDate,
        endDate,
        !fullUrlMode
      );
    } else {
      urls = await getProjectRedirectUrlsByDay(
        id,
        100,
        null,
        startDate,
        endDate,
        !fullUrlMode
      );
    }
    let resp = urls.items;
    setPageCursorRedirect(urls.page_cursor);
    if (resp) {
      for (let row of resp) {
        row["redirects"] = row?.redirects ? row.redirects.toFixed(0) : 0;
      }
    } else {
      setPageCursorRedirect(null);
    }
    if (dataSourceForRedirect.length > 0 && more) {
      resp = [...dataSourceForRedirect, ...resp];
    }

    const uniqueRows = [];
    resp.forEach((row) => {
      const existingRow = uniqueRows.find(
        (r) => r.original === row.original && r.match === row.match
      );

      if (existingRow) {
        existingRow.redirects += row.redirects;
      } else {
        uniqueRows.push(row);
      }
    });
    uniqueRows.sort((a, b) => b.redirects - a.redirects);
    setDataSourceForRedirect(uniqueRows);
  };

  const setProjectAggFilter = (
    startDate,
    endDate,
    fallbackProjectAgg = null
  ) => {
    let agg = projectAgg;

    if (projectAgg == null && fallbackProjectAgg != null) {
      agg = fallbackProjectAgg;
    }

    return agg.filter((item) => {
      const timestamp = moment(item.timestamp).toDate();
      return (
        timestamp >= startDate.startOf("day").toDate() &&
        timestamp <= endDate.endOf("day").toDate()
      );
    });
  };

  const handleFilter = async (date) => {
    if (date === null) {
      const endDate = moment().add((1, "day"));
      const startDate = moment().subtract(30, "days");
      console.log(startDate, endDate);
      setDateRange([startDate, endDate]);
      const projectAggFilter = setProjectAggFilter(startDate, endDate);
      await fetchMoreRedirectsByDay(
        false,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        !fullUrlMode
      );
      setProjectAggFiltered(projectAggFilter);
    } else {
      setDateRange(date);
      const dateStringRange = date.map((date) =>
        moment(date).format("YYYY-MM-DD")
      );
      const projectAggFilter = setProjectAggFilter(date[0], date[1]);
      setProjectAggFiltered(projectAggFilter);
      await fetchMoreRedirectsByDay(
        false,
        dateStringRange[0],
        dateStringRange[1],
        !fullUrlMode
      );
    }
  };

  const fetchData = useCallback(async () => {
    const projectDetail = await loadProjectDetail(id);
    setProjectDetail(projectDetail);
    const projectAgg = await getProjectAgg(id);
    setProjectAgg(projectAgg);
    const endDate = moment().add((1, "day"));
    const startDate = moment().subtract(30, "days");

    const projectAggFilter = setProjectAggFilter(
      startDate,
      endDate,
      projectAgg
    );
    setProjectAggFiltered(projectAggFilter);
    setDateRange([startDate, endDate]);
    await fetchMoreRedirectsByDay(
      false,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      !fullUrlMode
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fullUrlMode]);

  useEffect(() => {
    fetchData();
  }, [fetchData, fullUrlMode]);

  const tableLocale = {
    emptyText: (
      <Empty
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            There are no redirect logs for pervious 30 days. Please change date
            range to see previous logs.
          </span>
        }
      />
    ),
  };

  return (
    <div className="ProjectDetail">
      <Modal
        open={visible}
        title={
          "Paste this code in the <head> section of your 404 Page Template"
        }
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <div style={{ border: "1px solid #9a9a9a", padding: "20px" }}>
          <code>{script()}</code>
        </div>
      </Modal>
      <Modal
        open={visibleForm}
        title={"Add new Redirect"}
        onCancel={() => {
          setVisibleForm(false);
        }}
        footer={null}
        width={"70%"}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Col span={12} style={{ paddingRight: "10px" }}>
              <Form.Item
                className="AddProject__item"
                placeholder="Orignal Url"
                name="original"
                style={{ marginBottom: "40px" }}
                rules={[
                  { required: true, message: "Please enter a orignal url" },
                ]}
              >
                <Input.TextArea
                  placeholder="Original URLs"
                  rows="10"
                  max-rows="16"
                  className="textarea"
                  onChange={(e) => setOriginal(e.target.value)}
                ></Input.TextArea>
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingLeft: "10px" }}>
              <Form.Item
                className="AddProject__item"
                placeholder="Final Url"
                name="match"
                rules={[
                  {
                    required: true,
                    message: "Please enter final Url",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Final URLs"
                  rows="10"
                  max-rows="16"
                  className="textarea"
                  onChange={(e) => setMatch(e.target.value)}
                ></Input.TextArea>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <div style={{ color: "red", marginBottom: "5px" }}>
                {createError}
              </div>

              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> Create</span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={editModalOpen}
        title={"Edit Redirect mapping"}
        onCancel={() => {
          setEditModalOpen(false);
        }}
        footer={null}
        width={"70%"}
      >
        <Form
          name="basic"
          initialValues={{ remember: true, match: editRecordMatch }}
          autoComplete="off"
          layout="vertical"
          form={editForm}
          onFinish={onUrlEditSave}
        >
          <Row>
            <Col span={24} style={{ paddingRight: "10px" }}>
              <div style={{ margin: "10px" }}>
                Original: {editRedirectRecord?.original}
              </div>
            </Col>

            <Col span={24} style={{ paddingLeft: "10px" }}>
              <Form.Item
                placeholder="Final Url"
                name="match"
                rules={[
                  {
                    required: true,
                    message: "Please enter final Url",
                  },
                ]}
              >
                <Input
                  placeholder="Final URL"
                  value={editRecordMatch}
                  onChange={(e) => setEditRecordMatch(e.target.value)}
                ></Input>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <div style={{ color: "red", marginBottom: "5px" }}>
                {createError}
              </div>

              <Button
                type="primary"
                htmlType="submit"
                disabled={editRecordMatch === ""}
              >
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> Save</span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      
      <Button
        size={"large"}
        type="primary"
        style={{ float: "right" }}
        onClick={onCreateRedirect}
      >
        Add Redirect
      </Button>
      <RangePicker
        value={dateRange}
        onChange={handleFilter}
        style={{
          float: "right",
          marginRight: "13px",
          fontSize: "26px",
        }}
      />
      <Button
        size={"large"}
        type="text"
        onClick={() => setVisible(true)}
        style={{
          float: "right",
          fontSize: "25px",
          padding: 0,
          marginRight: "13px",
          marginTop: "3px",
          color: "#545454",
        }}
      >
        <CodeOutlined></CodeOutlined>
      </Button>
      
      <CsvDownloadButton
        data={dataSourceForRedirect}
        filename={`URL_Mappings_${projectDetail.name}.csv`}
        style={{
          background: "transparent",
          float: "right",
          border: "none",
          cursor: "pointer",
          fontSize: "20px",
          marginTop: "5px",
          marginRight: "5px",
        }}
      >
        <DownloadOutlined />
      </CsvDownloadButton>

      <div style={{float: "right", marginRight:"20px", marginTop:"10px"}}>
      <Switch 
              defaultChecked={true}
              checked={fullUrlMode}
              onChange={onStripUrlToggleChange}
            /> Full URLs
      </div>

      <h2>{projectDetail.name}</h2>
      {!loading &&
        (dataSourceForRedirect ? (
          <div>
            <div style={{ width: "100%", marginTop: "20px" }}>
              {projectAggFiltered && projectAggFiltered.length > 0 && (
                <ResponsiveContainer width="100%" aspect={5 / 1}>
                  <LineChart
                    data={projectAggFiltered}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" tickFormatter={dateFormatter} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="count"
                      name="redirects"
                      strokeWidth={"2.5px"}
                      stroke="#8884d8"
                      activeDot={{ r: 5 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>

            <Table
              columns={columns}
              showHeader={true}
              size="small"
              dataSource={dataSourceForRedirect}
              pagination={false}
              rowKey="project"
              locale={tableLocale}
            ></Table>
            {pageCursorRedirect && (
              <Button
                style={{ marginTop: "10px", marginBottom: "20px" }}
                onClick={fetchMoreRedirectsByDay}
              >
                More...
              </Button>
            )}
          </div>
        ) : (
          <p>
            There are no redirect logs yet. Please check back in a few days.
          </p>
        ))}
      {loading && <Spin indicator={antIconBlack}></Spin>}
    </div>
  );
}

export default observer(Project);
