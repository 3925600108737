import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Switch } from "antd";
import { updateUser, loadRoles } from "../../service/api_service";
import ChangePassword from "../security/ChangePassword";

function Security({ jobStore, authStore }) {
  jobStore.setNavigation("setting");

  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(false);

  const antIconLarge = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "black", margin: "0 5px" }}
      spin
    />
  );



  return (
    <div className="Security">
      {loading && <Spin indicator={antIconLarge}></Spin>}
      {!loading && (
        <div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <h3 style={{marginBottom: "30px"}}>Change Password</h3>
            <ChangePassword authStore={authStore}/>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(Security);
