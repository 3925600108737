import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { config } from "../../utils/config";

const userPoolId = config.COGNITO_POOL_ID;
const clientId = config.COGNITO_CLIENT_ID;

var poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};
export var userPool = new CognitoUserPool(poolData);

export function signup(username, password, email, firstname, lastname) {
  var attributeList = [];

  var attributeEmail = new CognitoUserAttribute({
    Name: "email",
    Value: email,
  });
  var attributeFirstname = new CognitoUserAttribute({
    Name: "given_name",
    Value: firstname,
  });
  var attributeLastname = new CognitoUserAttribute({
    Name: "family_name",
    Value: lastname,
  });

  attributeList.push(attributeEmail);
  attributeList.push(attributeFirstname);
  attributeList.push(attributeLastname);

  return new Promise((resolve, reject) => {
    userPool.signUp(
      username,
      password,
      attributeList,
      null,
      function (err, result) {
        if (err) {
          reject(err);
        }
        resolve(result);
      }
    );
  });
}

export function resendConfirmation(username) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function forgotPassword(username) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function confirmForgotPassword(username, verificationCode, newPassword) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function completeChangePassword(cognitoUser, newPassword) {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function changePassword(currentPassword, newPassword) {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log("Error getting session");
        reject(err);
        return;
      }

      cognitoUser.changePassword(
        currentPassword,
        newPassword,
        function (err, result) {
          if (err) {
            reject(err);
            return;
          }
          resolve(result);
        }
      );
    });
  });
}

export function verifyAccount(username, code) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function authenticateUser(username, password) {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
      newPasswordRequired: function (result) {
        reject({ type: "NEW_PASSWORD_REQUIRED", cognitoUser: cognitoUser });
      },
    });
  });
}

export async function signout(username) {
  const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
  cognitoUser.signOut();
}

export async function getCurrentUser() {
  return userPool.getCurrentUser();
}

export async function userSession() {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser == null) {
      reject("No valid session");
    }

    cognitoUser.getSession(function (err, session) {
      if (err) {
        reject(err);
      }
      const expiredIdToken =
        new Date().getTime() / 1000 > session.getIdToken().payload.exp;
      if (expiredIdToken) {
        cognitoUser.refreshSession(
          session.getRefreshToken(),
          (err, session) => {
            if (err) {
              reject(err);
            }
            resolve(session);
          }
        );
      } else {
        resolve(session);
      }
    });
  });
}

export async function refreshOrGet(session) {
  const expiredIdToken =
    new Date().getTime() / 1000 > session.getIdToken().payload.exp;
  return new Promise((resolve, reject) => {
    if (expiredIdToken) {
      userPool
        .getCurrentUser()
        .refreshSession(session.getRefreshToken(), (err, session) => {
          if (err) {
            reject(err);
          }
          resolve(session);
        });
    } else {
      resolve(session);
    }
  });
}
