import React, { useState, useEffect, useRef, useMemo } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import {
  LoadingOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Table, Modal, Spin , Button} from "antd";
import { loadJob, deleteJob, loadJobDetail } from "../../service/api_service";
import { useNavigate } from "react-router-dom";
import CsvDownloadButton from "react-json-to-csv";
import csvDownload from 'json-to-csv-export';

import "./Jobs.css";
const { confirm } = Modal;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />
);

function Jobs({ jobStore, authStore }) {
  const [loading, setLoading] = useState(false);

  const [jobList, setJobList] = useState([]);
  const [time, setTime] = useState(1);

  const [deleting, setDeleting] = useState([]);
  const [remainingTime, setRemainingTime] = useState(15);
  const navigate = useNavigate();


  const remaining = useMemo(() => {
    const status = {};
    jobList.forEach(record => {
      const timeoutDuration = record?.mode === "elastic" ? 60 : 15 * 60;
      const diffSeconds =
        (moment.utc().toDate().getTime() -
          moment.utc(record.timestamp).toDate()) /
        1000;
      const rt = timeoutDuration - diffSeconds;
      status[record.job_id] = rt;
    });
    return status;
  }, [ time, jobList]);

  const update2 = () => {
    setTime(Math.random());
    setTimeout(() => {
      update2();
    }, 5000);
  }

  const fetch_data_to_download = async (jobId) => {
    const job_detail = await loadJobDetail(jobId);
    csvDownload({data: job_detail.prediction, filename: `URL_Mappings_${jobId}.csv`});
  }

  useEffect(() => {
      update2();
  }, []);
  
  const columns = [
    {
      dataIndex: "name",
      key: "name",
      title: "Name",
      render: (_, record) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{ color: "#343434" }}
            onClick={() => {
              navigate(`/job/${record.job_id}`);
            }}
          >
            {record.name}
          </a>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      render: (status, record) => {
        const remainingTime = remaining[record.job_id];
        if (status === "in-progress" || status === "created") {
          if (remainingTime > 0) {
            if (record?.mode === "elastic") {
              return <span> {`${remainingTime} second(s) remaining`}</span>;
            } else {
              return (
                <span>
                  {`${Math.floor(remainingTime / 60)} minute(s)`} remaining
                </span>
              );
            }
          } else {
            return <span>{"timeout"}</span>;
          }
        } else {
          return <span>{status}</span>;
        }
      },
    },
    {
      dataIndex: "prediction",
      key: "prediction",
      width: "100px",
      render: (_, record) => {
      
        return (
          <div>
            {record?.prediction && (
              <CsvDownloadButton
                className="Jobs__action"
                data={record?.prediction}
                filename={`URL_Mappings_${record.job_id}.csv`}
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <DownloadOutlined />
              </CsvDownloadButton>
            )}
            {record?.prediction_path && (
              <a
                className="Jobs__action"
                onClick={()=>fetch_data_to_download(record.job_id)}
                style={{

                }}
              >
                <DownloadOutlined />
              </a>
            )}
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key="delete"
                className="Jobs__action"
                onClick={() => showDeleteConfirm(record.job_id, record.name)}
              >
                {deleting.indexOf(record.job_id) >= 0 ? (
                  <Spin indicator={antIcon}></Spin>
                ) : (
                  <DeleteOutlined />
                )}
              </a>
            }
          </div>
        );
      },
    },
  ];

  const loadData = async () => {
    const jobs = await loadJob();
    setJobList(jobs);

    const hasInProgressJobs =
      jobs.filter((x) => x.status === "created" || x.status === "in-progress")
        .length > 0;
    if (hasInProgressJobs) {
      setTimeout(() => {
        loadData();
      }, 15000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await loadData();
      setLoading(false);
    };
    fetchData();
  }, [jobStore.jobCounter]);

  const showDeleteConfirm = (jobId, name) => {
    const title = `Are you sure you want to delete job "${name}"`;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "cancel",
      onOk() {
        performDeletion(jobId);
      },
      onCancel() {},
    });
  };

  const performDeletion = async (jobId) => {
    setDeleting([...deleting, jobId]);
    try {
      await deleteJob(jobId);

      await loadData();
    } finally {
      setDeleting(deleting.splice(deleting.indexOf(jobId), 1));
    }
  };

  return (
    <div className="Jobs">
      <h2>Jobs</h2>
      {!loading && jobList.length > 0 && (
        <Table
          columns={columns}
          showHeader={true}
          dataSource={jobList}
          rowKey="job_id"
        ></Table>
      )}
      {loading && <Spin indicator={antIcon}></Spin>}

      {!loading && jobList.length === 0 && (
        <div>
          <p>Looks like there aren't any jobs submitted yet.</p>
        </div>
      )}
    </div>
  );
}

export default observer(Jobs);
