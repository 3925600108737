import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Switch } from "antd";
import { updateUser, loadRoles } from "../../service/api_service";

function Notifications({ jobStore }) {
  jobStore.setNavigation("setting");

  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enableReports, setEnableReports] = useState(true);

  const antIconLarge = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "black", margin: "0 5px" }}
      spin
    />
  );

  useEffect(() => {
    if (!me) {
      return;
    }
    setEnableReports(
      !me.hasOwnProperty("reporting_enabled") || me.reporting_enabled
    );
  }, [me]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const roles_ = loadRoles(true);
      const { me, roles } = await roles_;
      setMe(me);
      setLoading(false);
    };
    fetch();
  }, []);

  const onEnableReportsChange = (checked) => {
    setEnableReports(checked);
    updateUser(checked);
  };

  return (
    <div className="Notifications">
      {loading && <Spin indicator={antIconLarge}></Spin>}
      {!loading && (
        <div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <h3>Notifications</h3>
            <Switch
              defaultChecked={true}
              checked={enableReports}
              onChange={onEnableReportsChange}
            />
            <span style={{ paddingLeft: "7px" }}>Email weekly reports</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(Notifications);
