import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Button, Form } from "antd";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { associateCard, subscribe } from "../../service/api_service";
import {loadPlans} from "../../service/api_service";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
);


function CardSetup({ jobStore, cardClientSecret, me, refresh, close, onPrev }) {
  jobStore.setNavigation("account");

  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState("");
  const [plans, setPlans] = useState();

  const [submitting, setSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onFinish = async () => {
    if (cardError) {
      return;
    }
    try {
      setSubmitting(true);
      const resp = await submitCard();
      const paymentMethod = resp.setupIntent["payment_method"];
      await associateCard(paymentMethod);
      if (localStorage.hasOwnProperty("plan")) {
        const plan = localStorage.getItem("plan");
        localStorage.removeItem("plan");
        await subscribe(plan, "monthly");
      }
      await refresh();
      close(false);
    } catch (e) {

    }
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const plans = await loadPlans();
      console.log(plans);
      setPlans(plans);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleCardChange = async (event) => {
    setCardError(event.error ? event.error.message : "");
    console.log(event?.error?.message);
  };

  const submitCard = () => {
    return new Promise((resolve, reject) => {
      stripe
        .confirmCardSetup(cardClientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: me.billing_details,
          },
        })
        .then(function (result) {
          if (result.error) {
            reject(result.error.message);
          } else {
            resolve(result);
          }
        });
    });
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const plan = localStorage.getItem("plan");
  const charge = plans && plans[plan] &&  plans[plan]["monthly"] ? plans[plan]["monthly"]["price"] : "-";
  return (
    <div className="CardSetup">
      <div style={{margin: "20px 0 20px 0"}}>
        Enter your credit card info. A total amount of ${charge} will be charged for your selected plan - {plan}.
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        style={{ "marginTop": "10px" }}
      >
        <CardElement
          options={cardStyle}
          onChange={handleCardChange}
        ></CardElement>
        {cardError && (
          <div style={{ color: "red", marginTop: "5px" }}>{cardError}</div>
        )}

        <Form.Item style={{ marginTop: "40px" }}>
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              {submitting && <Spin indicator={antIcon} />}
              {!submitting && <span> Save Card & Make a Payment</span>}
            </Button>
            {onPrev &&
              <Button type="primary" onClick={onPrev} style={{ float: "left" }}>
                <span> Back</span>
              </Button>
            }
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default observer(CardSetup);
