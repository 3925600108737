import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  MailOutlined,
  SettingOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Modal,
  Button,
  Switch,
  Form,
  Input,
  Spin,
  notification,
  message,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { getUploadURLs, submitMatcher } from "../../service/api_service";

import "./MapperInput.css";
import { useNavigate } from "react-router-dom";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
);

function MapperInput({ jobStore }) {
  const [wordpress, setWordpress] = useState(false);
  const [showName, setShowName] = useState(true);

  const [name, setName] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [finalText, setFinalText] = useState("");

  const [fileUpload, setFileUpload] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fastMode, setFastMode] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(false);
  const [rateError, setRateError] = useState(false);

  const [fileUrl, setFileUrl] = useState();
  const [originalFileKey, setOriginalFileKey] = useState("");
  const [finalFileKey, setFinalFileKey] = useState("");

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const navigate = useNavigate();

  const parentRedirect = (url) => {
    window.top.location.href = url;
  };

  useEffect(() => {
    const wordpress_ = process.env.REACT_APP_BUILD_TARGET === "wordpress";
    setWordpress(wordpress_);
    if (wordpress_) {
      setName("Redirect Mapper");
    }
  }, []);

  const onPaste = (p, updateText) => {
    setTimeout(() => {
      const text = p.target.value;
      const arr = text.split("\n");
      const length = arr.length;
      if (wordpress && length > 100) {
        const slicedText = arr.slice(0, 100).join("\n");
        updateText(slicedText);
        setIsErrorModalOpen(true);
      }
    }, 50);
  };

  const beforeUpload = (file) => {
    const isText = file.type === "text/plain";
    if (!isText) {
      message.error("Please ensure the selected file is a text file.");
    }
    return isText;
  };

  const clear = async () => {
    setName("");
    setOriginalText("");
    setFinalText("");

    setOriginalFileKey("");
    setFinalFileKey("");

    if (fileUpload) {
      const urls = await getUploadURLs();
      setFileUrl(urls);
    }
  };
  const submitClicked = async () => {
    setSubmitting(true);

    try {
      const job = await submitMatcher(
        name,
        originalText.split("\n"),
        finalText.split("\n"),
        fileUpload ? originalFileKey : null,
        fileUpload ? finalFileKey : null,
        fastMode ? "elastic" : "embedding"
      );
      jobStore.incrementJobCounter();
      notification.success({ message: "Job submitted successfully" });
      clear();
      if (wordpress) {
        navigate(`/job/${job.job_id}`);
      }
    } catch (error) {
      console.log(error);
      const message = error.response.data.message;

      if (message.indexOf("Max allowed") >= 0) {
        setRateLimitError(message);
      } else {
        notification.error(message);
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    async function generate() {
      const urls = await getUploadURLs();
      const fileURL = urls;
      setFileUrl(fileURL);
    }
    if (fileUpload) {
      generate();
    }
  }, [fileUpload]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info, fileObj, setKey) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        const key = fileObj.fields.key;
        setKey(key);
      });
    }
  };

  useEffect(() => {
    if (fileUpload) {
      const submitEnabled =
        name &&
        originalFileKey &&
        finalFileKey &&
        name.length > 0 &&
        originalFileKey.length > 0 &&
        finalFileKey.length > 0;
      setSubmitEnabled(submitEnabled);
    } else {
      const submitEnabled =
        name &&
        originalText &&
        finalText &&
        name.length > 0 &&
        originalText.length > 0 &&
        finalText.length > 0;
      setSubmitEnabled(submitEnabled);
    }
  }, [
    name,
    originalText,
    finalText,
    fileUpload,
    originalFileKey,
    finalFileKey,
  ]);

  return (
    <div className="MapperInput">
      <Modal
        title="100 URL Limit Reached"
        open={isErrorModalOpen}
        onCancel={()=>setIsErrorModalOpen(false)}
        footer={
          <Button
            type="primary"
            onClick={() =>
              parentRedirect("https://app.redirectmapper.com/signup/FREE")
            }
          >
            Signup
          </Button>
        }
      >
        <p class="my-2">Sign Up Today To Increase Your Limit.</p>
      </Modal>

      <Modal
        title="Limit Reached"
        open={rateLimitError}
        onCancel={()=>setRateLimitError(false)}
        footer={<div></div>}
      >
        <p class="my-2">{rateLimitError}</p>
        {wordpress && <div>
          <p className="my-2">Sign Up Today To Increase Your Limit.</p>
          <Button
            className="btn saasbox-btn white-btn pointer"
            style={{
              backgroundColor: "#fdd76e",
              color: "#2b2b2b",
              marginTop: "20px",
            }}
            onClick={() =>
              parentRedirect("https://app.redirectmapper.com/signup/FREE")
            }
          >
            <span>Sign Up</span>
          </Button>
          </div>}
        {!wordpress && <div>
          <p className="my-2">Upgrade your plan to increase your limit.</p>
          <Button
            className="btn saasbox-btn white-btn pointer"
            style={{
              backgroundColor: "#fdd76e",
              color: "#2b2b2b",
              marginTop: "20px",
            }}
            onClick={() =>
              navigate("/account")
            }
          >
            <span>Upgrade now</span>
          </Button>
          </div>}

      </Modal>

      <Modal
        id="modal-signup"
        title="100 URL Limit Reached"
        ok-only
        hide-footer
        ok-title="Signup"
      >
        <p className="my-2">Sign Up Today To Increase Your Limit.</p>

        <div className="login-btn-area ms-4 mt-4 mt-lg-0 mt-2">
          <Button
            className="btn saasbox-btn white-btn pointer"
            style={{
              backgroundColor: "#fdd76e",
              color: "#2b2b2b",
              marginTop: "20px",
            }}
            onClick={() =>
              parentRedirect("https://app.redirectmapper.com/signup/FREE")
            }
          >
            <span>Sign Up</span>
          </Button>
        </div>
      </Modal>

      {!wordpress && (
        <div style={{ marginBottom: "20px" }}>
          <div className="formItem">
            <Switch defaultChecked={fileUpload} onChange={setFileUpload} /> File
            Upload
          </div>
          <div className="formItem">
            <Switch defaultChecked={fastMode} onChange={setFastMode} /> Fast
            Mode
          </div>
        </div>
      )}
      {!wordpress && showName && (
        <Row style={{ clear: "right" }}>
          <Col span={24}>
            <Form.Item>
              <Input
                size="large"
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Job name"
                required
              ></Input>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row>
        <Col span="12" className="Mapper__colr">
          {!fileUpload && (
            <Form.Item>
              <Input.TextArea
                placeholder="Original URLs"
                rows="10"
                max-rows="16"
                className="textarea"
                onPaste={(e) => onPaste(e, setOriginalText)}
                value={originalText}
                onChange={(e) => setOriginalText(e.target.value)}
              ></Input.TextArea>
            </Form.Item>
          )}

          {fileUpload && (
            <Upload
              data={fileUrl?.original?.fields}
              showUploadList={true}
              action={fileUrl?.original?.url}
              beforeUpload={beforeUpload}
              multiple={false}
              maxCount={1}
              onChange={(info) =>
                handleChange(info, fileUrl.original, setOriginalFileKey)
              }
            >
              <Button icon={<UploadOutlined />}>
                Select original url file
              </Button>
            </Upload>
          )}
        </Col>

        <Col span="12" className="Mapper__coll">
          {!fileUpload && (
            <Form.Item>
              <Input.TextArea
                placeholder="Final URLs"
                rows="10"
                max-rows="16"
                className="textarea"
                onPaste={(e) => onPaste(e, setFinalText)}
                value={finalText}
                onChange={(e) => setFinalText(e.target.value)}
              ></Input.TextArea>
            </Form.Item>
          )}

          {fileUpload && (
            <Upload
              data={fileUrl?.final?.fields}
              showUploadList={true}
              action={fileUrl?.final?.url}
              beforeUpload={beforeUpload}
              multiple={false}
              maxCount={1}
              onChange={(info) =>
                handleChange(info, fileUrl.final, setFinalFileKey)
              }
            >
              <Button icon={<UploadOutlined />}>Select final url file</Button>
            </Upload>
          )}
        </Col>
      </Row>

      <div>
        {/* <Alert variant="danger" dismissible>
          {{ rateLimitError }}
          {wordpress ?  <span >
            <a  onClick={() => parentRedirect('https//app.redirectmapper.com/signup/SINGLE_USER')}
              style={{"padding" :"5px 10px", "line-height":"20px", "height": "30px"}}>Signup</a>
          </span> : <span>Sign Up Today To Increase Your Limit.</span>}
        </Alert> */}

        <Button
          type="primary"
          block
          size="large"
          style={{ marginTop: "20px" }}
          disabled={!submitEnabled}
          onClick={() => submitClicked()}
        >
          {submitting ? (
            <Spin indicator={antIcon}></Spin>
          ) : (
            <span>Map URLs</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default observer(MapperInput);
