import axios from "axios";
import {
    CognitoUser,
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
    CognitoUserSession,
} from "amazon-cognito-identity-js";
import { userPool } from "../components/security/cognito";
import { createUser } from "./api_service";
import {config} from "../utils/config";

const AUTH_URL = config.AUTH_URL;

export async function loginCallback(code) {
    const url = `${AUTH_URL}/token`;
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("client_id", config.COGNITO_CLIENT_ID);
    params.append("redirect_uri", config.REDIRECT_URI);
    const config2 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
    const data = (await axios.post(url, params, config2)).data;
    delete data.expires_in;
    delete data.token_type;
    const newKeys = {
        id_token: "IdToken",
        refresh_token: "RefreshToken",
        access_token: "AccessToken",
    }
    const newData = renameKeys(data, newKeys)
    newData["IdToken"] = new CognitoIdToken({ IdToken: newData["IdToken"] })
    newData["RefreshToken"] = new CognitoRefreshToken({ RefreshToken: newData["RefreshToken"] })
    newData["AccessToken"] = new CognitoAccessToken({ AccessToken: newData["AccessToken"] })
    const  cognitoUser = new CognitoUser({
        Username: newData["IdToken"].payload["cognito:username"],
        Pool: userPool
    })
    await createUser(newData["IdToken"].payload["cognito:username"], "FREE", null)
    const session = new CognitoUserSession(newData)
    cognitoUser.setSignInUserSession(session)
    return session;
}

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}
