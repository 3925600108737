import React, { useEffect, useState } from 'react';
import { Steps, message } from 'antd';
import PlanSelection from './PlanSelection';
import CardBillingInfo from './CardBillingInfo';
import CardSetup from './CardSetup';
import { loadRoles, setupIntent } from '../../service/api_service';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config } from '../../utils/config';

import "./PaymentStepWizard.css";

const { Step } = Steps;

const PaymentStepWizard = ({ jobStore, refresh }) => {
	jobStore.setNavigation("account");

	const [me, setMe] = useState(null);
	const [cardClientSecret, setCardClientSecret] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);

	const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);


	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const handleFinish = () => {
		message.success('Account setup completed!');
	};

	useEffect(() => {
		const fetch = async () => {
			const roles_ = loadRoles(true);
			const { me } = await roles_;
			setMe(me);
			const intent = await setupIntent();
			setCardClientSecret(intent.client_secret);
		};
		fetch();
	}, []);

	return (
		<div className="step-wizard">
			<Steps current={currentStep}>
				<Step title="Choose Plan" />
				<Step title="Billing Info" />
				<Step title="Credit Card Info" />
			</Steps>

			<div className="steps-content">
				{currentStep === 0 &&
					<PlanSelection
						jobStore={jobStore}
						refresh={refresh}
						me={me}
						close={nextStep}
						stepWizard={true}
					/>
				}
				{currentStep === 1 &&
					<CardBillingInfo
						jobStore={jobStore}
						me={me}
						refresh={refresh}
						close={nextStep}
						onPrev={prevStep}
					/>
				}
				{currentStep === 2 &&
				<Elements stripe={stripePromise}>
					<CardSetup
						cardClientSecret={cardClientSecret}
						close={handleFinish}
						jobStore={jobStore}
						me={me}
						refresh={refresh}
						onPrev={prevStep}
					/>
				</Elements>
				}
			</div>
		</div>
	);
};

export default PaymentStepWizard;
