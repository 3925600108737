import { observer } from "mobx-react-lite";
import MapperInput from "./components/manual/MapperInput";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import JobDetail from "./components/manual/JobDetail";

function Wordpress({ authStore, jobStore }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div style={{ margin: "20px" }}>
              <MapperInput jobStore={jobStore}></MapperInput>
            </div>
          }
        />

        <Route
          path="/job/:id"
          element={
            <div style={{ margin: "20px" }}>
              <JobDetail authStore={authStore} jobStore={jobStore} />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default observer(Wordpress);
