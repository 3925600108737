import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { MailOutlined, SettingOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import MapperInput from "./MapperInput";
import Jobs from "./Jobs";

import "./Manual.css";

function Manual({ jobStore, authStore }) {
  jobStore.setNavigation("manual");
  
  const [wordpress, setWordpress] = useState(false);
  useEffect(() => {
    setWordpress(process.env.REACT_APP_BUILD_TARGET === "wordpress");
  }, []);

  return (
    <div className="Manual">
      <MapperInput jobStore={jobStore}></MapperInput>
      {!wordpress && <Jobs jobStore={jobStore} authStore={authStore}></Jobs>}
    </div>
  );
}

export default observer(Manual);
