import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {createUser} from "../../service/api_service";
import {
  signup,
  authenticateUser,
  verifyAccount,
  resendConfirmation,
} from "./cognito";
import { useNavigate, useParams } from "react-router-dom";
import {config} from "../../utils/config";

import "./Signup.css";
import "./Login.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function Signup({ authStore, navStore }) {
  const [resending, setResending] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  const [newPassword, setNewPassword] = useState(true);
  const [newPassword2, setNewPassword2] = useState(true);
  const [user, setUser] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  const navigate = useNavigate();
  const { option } = useParams();

  const onEmailFinish = async () => {
    setStep(2);
  };

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    try {
      if (newPassword !== newPassword2) {
        setError("Password mismatch");
        setSubmitting(false);
      } else {
        try {
          const result = await signup(
            email,
            newPassword,
            email,
            firstName,
            lastName
          );
          await createUser(email, "FREE", company);
          setUser(result.user);
        } catch (e) {
          setError(e.message);
          setSubmitting(false);
        }
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setError(e.message);
    }
  };

  const autoLogin = async () => {
    await authenticateUser(email, newPassword);
  };

  const resendCode = async () => {
    setResending(true);
    try {
      await resendConfirmation(email);
    } finally {
      setResending(false);
    }
  };

  const verify = async () => {
    setSubmitting(true);
    setError("");
    try {
      await verifyAccount(user.username, verificationCode);
      await autoLogin();
      navigate("/");
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const onGoogleLogin = () => {
    const URL = `${config.AUTH_URL}/authorize?identity_provider=Google&redirect_uri=${config.REDIRECT_URI}&response_type=CODE&client_id=${config.COGNITO_CLIENT_ID}&scope=openid%20email%20profile`;
    window.location.replace(URL);
  };

  return (
    <div className="Signup">
      <div className="Signup__wrapper">
        <h1 className="Signup__header">Create your free account</h1>
        <div className="Signup__container">
          {user == null && step === 1 && (
            <React.Fragment>
              <Button
                class="google-btn w-100"
                type="text"
                onClick={onGoogleLogin}
                block
              >
                <img
                  class="google-icon"
                  style={{marginRight:"10px"}}
                  width="15px"
                  src="/Google__G__logo.svg"
                />
                Sign in with Google
              </Button>
              <p class="register-card__text">OR</p>

              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                onFinish={onEmailFinish}
              >
                <Form.Item
                  className="Signup__item"
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Please enter an email" }]}
                >
                  <Input onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>

                {error && <div className="error">{error}</div>}
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    <span> Continue</span>
                  </Button>
                </Form.Item>
              </Form>
            </React.Fragment> 
          )}

          {user == null && step === 2 && (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                className="Signup__item"
                label="First name"
                name="name"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input onChange={(e) => setFirstName(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="Last name"
                name="lastname"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input onChange={(e) => setLastName(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="Company"
                name="company"
                rules={[{ required: false }]}
              >
                <Input onChange={(e) => setCompany(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter a new password!" },
                ]}
              >
                <Input.Password
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="Re-enter Password"
                name="newPassword1"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter your password!",
                  },
                ]}
              >
                <Input.Password
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> Signup</span>}
                </Button>
              </Form.Item>
            </Form>
          )}
          {user != null && (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={verify}
            >
              <p>
                We've sent a code to your email. Please enter the verification
                code.
              </p>
              <Form.Item
                className="Signup__item"
                label="Verification code"
                name="verificationCode"
                rules={[
                  { required: true, message: "Please verification code" },
                ]}
              >
                <Input onChange={(e) => setVerificationCode(e.target.value)} />
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> Verify</span>}
                </Button>
              </Form.Item>
              <div className="Signup__resend_code">
                <button className="link" onClick={() => resendCode()}>
                  {resending && (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  )}
                  {!resending && <span> Resend code</span>}
                </button>
              </div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Signup);
