import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Card, Spin, Button, Row, Col } from "antd";

import { subscribe } from "../../service/api_service";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 18, color: "black", margin: "0 5px" }}
    spin
  />
);
function PlanSelection({ jobStore, me, refresh, close, stepWizard }) {
  jobStore.setNavigation("account");
  const [planInProgress, setPlanInProgress] = useState("");

  const selectPlan = async (plan) => {
    setPlanInProgress(plan);
    if (stepWizard) {
      localStorage.setItem("plan", plan);
    }
    else {
      await subscribe(plan, "monthly");
    }
    await refresh();
    setPlanInProgress("");
    close(false);
  };

  return (
    <div className="PlanSelection">
      <div style={{margin: "20px 0 20px 0"}}>Choose a plan that's right for you!</div>
      <Row>
        <Col span={8} className="Account__card">
          <Card hoverable>
            <div>
              <h3>Personal</h3>
              <span className="Account__dollar">$79</span>
              <span className="Account__frequency">per month</span>
            </div>
            <div className="Account__content">
              up to 1,000 redirects per month
              {(me && me.subscription && me.subscription === "PERSONAL") || localStorage.getItem("plan") === "PERSONAL" ? (
                <div style={{ marginTop: "23px" }}>
                  <span className="Account__currentplan">
                    {planInProgress === "PERSONAL" && (
                      <Spin indicator={antIcon}></Spin>
                    )}{" "}Current Plan
                  </span>
                </div>
              ) : (
                <Button
                  onClick={() => selectPlan("PERSONAL")}
                  block
                  style={{ marginTop: "15px" }}
                >
                  {planInProgress === "PERSONAL" && (
                    <Spin indicator={antIcon}></Spin>
                  )}{" "}
                  Select Personal
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8} className="Account__card">
          <Card hoverable>
            <div>
              <h3>Popular</h3>

              <span className="Account__dollar">$249</span>
              <span className="Account__frequency">per month</span>
            </div>
            <div className="Account__content">
              up to 10,000 redirects per month
              {(me && me.subscription && me.subscription === "POPULAR") || localStorage.getItem("plan") === "POPULAR" ? (
                <div style={{ marginTop: "23px" }}>
                  <span className="Account__currentplan">
                    {planInProgress === "POPULAR" && (
                      <Spin indicator={antIcon}></Spin>
                    )}{" "}Current Plan
                  </span>
                </div>
              ) : (
                <Button
                  onClick={() => selectPlan("POPULAR")}
                  block
                  style={{ marginTop: "15px" }}
                >
                  {planInProgress === "POPULAR" && (
                    <Spin indicator={antIcon}></Spin>
                  )}{" "}
                  Select Popular
                </Button>
              )}
            </div>
          </Card>
        </Col>
        <Col span={8} className="Account__card">
          <Card hoverable>
            <div>
              <h3>Agency</h3>
              <span className="Account__dollar">$499</span>
              <span className="Account__frequency">per month</span>
            </div>
            <div className="Account__content">
              <div>up to 50,000 redirects per month</div>
              {(me && me.subscription && me.subscription === "AGENCY") || localStorage.getItem("plan") === "AGENCY" ? (
                <div style={{ marginTop: "23px" }}>
                  <span className="Account__currentplan">
                    {planInProgress === "AGENCY" && (
                      <Spin indicator={antIcon}></Spin>
                    )}{" "}Current Plan
                  </span>
                </div>
              ) : (
                <Button
                  onClick={() => selectPlan("AGENCY")}
                  block
                  style={{ marginTop: "15px" }}
                >
                  {planInProgress === "AGENCY" && (
                    <Spin indicator={antIcon}></Spin>
                  )}{" "}
                  Select Agency
                </Button>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {
        localStorage.hasOwnProperty("plan") && planInProgress.length === 0 &&
        <Button type="primary" onClick={() => close()} style={{ margin: "1rem", float: "right" }}>
          <span> Next</span>
        </Button>
      }
    </div>
  );
}

export default observer(PlanSelection);
