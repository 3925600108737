export const config = process.env.REACT_APP_ENV === "production" ? {

  "URL": process.env.REACT_APP_BUILD_TARGET === "wordpress" ?
  "https://qcrhhq07kf.execute-api.us-east-1.amazonaws.com/prod/redirect-mapper" : 
  "https://7fxzgpntb1.execute-api.us-east-1.amazonaws.com/prod/redirect-mapper",

  "STRIPE_PUBLISHABLE_KEY" : "pk_live_51J2J1RHb6IzXS1y2ahzfxORgu2XfTgL8cu0oIQyhyoibepwPk1hqhZHaxzQ6PrxTGhQuG0J9uhRzPcIJnNIVzFTU00fV3Z70WX",
  "AUTH_URL" : "https://login.redirectmapper.com/oauth2",
  "REDIRECT_URI" : "https://app.redirectmapper.com/login-callback",
  "COGNITO_CLIENT_ID" : "4d284rqros6v7nps5pm2nlfnpt",
  "COGNITO_POOL_ID" : "us-east-1_SjdTQD7PF",
  "API_KEY": "g1fDzzuKeP41VtQTbriiDa3r25pleYdA9t6hAtOt",
} : {

  "URL": process.env.REACT_APP_BUILD_TARGET === "wordpress" ?
  "https://ws407vee7j.execute-api.us-east-1.amazonaws.com/dev/redirect-mapper" : 
  "https://wcek5wv2b2.execute-api.us-east-1.amazonaws.com/dev/redirect-mapper",
  "REDIRECT_URI": "https://d2dtvnh3x6ujeb.cloudfront.net/login-callback",
  "AUTH_URL": "https://redirect-mapper.auth.us-east-1.amazoncognito.com/oauth2",
  "STRIPE_PUBLISHABLE_KEY": "pk_test_51Kpm5bG89Rlyeegyp157vtFWJzyT8Maahk8lpB4Og6qOOCY1wLpvuv75Gp6XXdaviFqx51JlTYz2WQWBh4qpk0Bu00slR7NJyz",
  "API_KEY": "2689ws9Fyt2ZEASgTYmj47e8p2faItcDmq6SQDai",
  "COGNITO_CLIENT_ID": "7b6qb22i3e8vve3t7375di20fd",
  "COGNITO_POOL_ID": "us-east-1_6d3W6ieIY",
};
