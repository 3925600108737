import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import { Table, Spin } from "antd";
import { loadJobDetail } from "../../service/api_service";
import { useParams } from "react-router-dom";
import CsvDownloadButton from 'react-json-to-csv'


import "./JobDetail.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />
);

const columns = [
  {
    title: "Redirect from",
    dataIndex: "original",
    key: "original",
  },
  {
    title: "Redirect to",
    dataIndex: "prediction",
    key: "prediction",
  },
  {
    title: "Accuracy",
    dataIndex: "score",
    key: "score",
  },
];

function JobDetail({ JobDetailtore }) {
  let { id } = useParams();

  const [jobDetail, setJobDetail] = useState({});
  const [dataSource, setDataSource] = useState([]);

  const fetchData = async () => {
    const detail = await loadJobDetail(id);
    setJobDetail(detail);

    const resp = detail.prediction;
    if (resp) {
      for (let row of resp) {
        row["prediction"] = row.match;
        delete row["match"];
        row["score"] = row.score.toFixed(2);
      }
    }
    setDataSource(resp);

    if (detail.status === "created" || detail.status === "in-progress") {
      setTimeout(() => {
        fetchData();
      }, 2500);
    }

  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="JobDetail">
      {jobDetail.status === "complete" &&
        <CsvDownloadButton
          className="Jobs__action"
          data={dataSource}
          filename={`URL_Mappings_${jobDetail.job_id}.csv`}
          style={{
            background: "transparent",
            border: "none",
            cursor: "pointer",
            float: "right",
          }}>
          <DownloadOutlined />
        </CsvDownloadButton>}
      <h2>{process.env.REACT_APP_BUILD_TARGET=="wordpress" ? "Mapping Results" : jobDetail?.name}</h2>


      {(jobDetail.status === "created" ||
        jobDetail.status === "in-progress") && (
          <div className="waiting">
            <Spin style={{ marginRight: "10px" }} indicator={antIcon}></Spin>
            Please wait while the model is being trained.
          </div>
        )}
      {jobDetail.status === "timeout" && (
        <div>The job timed out. Please try again.</div>
      )}

      {jobDetail.status === "failed" && (
        <div>The job failed. Please try again.</div>
      )}

      {jobDetail.status === "complete" && (
        <div>
          <Table
            columns={columns}
            showHeader={true}
            dataSource={dataSource}
            rowKey="original"
          ></Table>
        </div>
      )}
    </div>
  );
}

export default observer(JobDetail);
